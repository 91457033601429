<template>
  <div>
    <a-table :columns="columns"
      :data-source="list"
      :pagination="pagination"
      @change="handleTableChange"
      @expand="onExpand">
      <div slot="expandedRowRender" slot-scope="record" style="margin: 0" expandIcon="">
        <yu-zhong-chart-result :male_id="male_item.id" :female_id="record.id" :ref="`result-${record.vid}`"></yu-zhong-chart-result>
      </div>
      <template slot="father" slot-scope="father, row">
        <item-button :text="row.item_father" :id="row.father_id" :is_dead="row.is_dead"></item-button>
      </template>
      <template slot="mother" slot-scope="mother, row">
        <item-button :text="row.item_mother" :id="row.mother_id" :is_dead="row.is_dead"></item-button>
      </template>
      <template slot="vid" slot-scope="vid, row">
        <item-button :text="row.vid" :id="row.id" :is_dead="row.is_dead"></item-button>
      </template>
      <template slot="operation" slot-scope="scope">
        <a-dropdown>
          <a-menu slot="overlay" @click="(e) => handleItemAction(e, scope)">
            <a-menu-item key="add_yu_zhong_record"> <a-icon type="check" />选用此配对</a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px" size="small"> 操作 <a-icon type="down" /> </a-button>
        </a-dropdown>
      </template>
    </a-table>
    <a-modal :visible="show_confirm" title="选择确认">
      <template slot="footer">
        <a-button key="back" @click="show_confirm=false;picked_item={}">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="onConfirm">
          确认
        </a-button>
      </template>
      <a-row>
        <a-col :span="12">
          <a-descriptions title="雄性个体">
            <a-descriptions-item label="vid">
              {{ male_item.vid }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="12">
          <a-descriptions title="雌性个体">
            <a-descriptions-item label="vid">
              {{ picked_item.vid }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import ItemButton from '../../components/ItemButton.vue'
import yuZhongChartResult from './yuZhongChartResult.vue'
export default {
  components: {yuZhongChartResult, ItemButton},
  props: {
    male_item: {
      type: Object, default: () => {}
    },
  },
  data() {
    return {
      pagination: {
        current: 1
      },
      list: [],
      picked_item: {},
      show_confirm: false,
      columns: [
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Vid",
          dataIndex: "vid",
          key: "vid",
          scopedSlots: {customRender: 'vid'}
        },
        {
          title: "RF-Id",
          dataIndex: "rf_id",
          key: "rf_id",
          scopedSlots: {customRender: 'rf_id'}
        },
        {
          title: "父亲",
          dataIndex: "father",
          key: "father",
          scopedSlots: {customRender: 'father'}
        },
        {
          title: "母亲",
          dataIndex: "mother",
          key: "mother",
          scopedSlots: {customRender: 'mother'}
        },
        {
          title: "出生日期",
          dataIndex: "birth_date",
          key: "birth_date",
          scopedSlots: {customRender: 'birth_date'}
        },
        {title: '操作', key: 'operation', scopedSlots: { customRender: 'operation' } }
      ]
    }
  },
  created() {
    if (this.male_item.id) {
      this.$api.get_breed_item_by_id(this.male_item.id).then(res => {
        this.male_item = res.data
      })
    }
  },
  methods: {
    syncData(list, pagination) {
      this.list = list
      this.pagination = pagination
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.$emit("page-change", pagination.current)
    },
    onExpand(expanded, record) {
      this.$nextTick(() => {
        if (expanded) {
          this.$refs[`result-${record.vid}`].draw(this.male_item.id, record.id)
        }
      })
    },
    handleItemAction(e, item) {
      this.show_confirm = true
      this.picked_item = item
    },
    handleAddYuZhongConfirm() {
      if (e.key == 'add_yu_zhong_record') {
        this.$api.post_yu_zhong_records({
          male_id: this.male_item.id,
          female_id: item.id,
          farm_id: item.farm_id
        })
      }
    },
    onConfirm() {
      const payload = {
        male_id: this.male_item.id,
        female_id: this.picked_item.id,
        farm_id: this.picked_item.farm_id
      }
      this.$api.post_yu_zhong_records(payload).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功")
          this.show_confirm = false
        } else {
          this.$message.error("服务器开小差了，请重试")
        }
      })
    }
  },
}
</script>

<style>
</style>